@layer tailwind-base {
  @tailwind base;
}
@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

body {
  font-family: Raleway, sans-serif;
  color: #34313b;
}
.text-red {
  color: red;
}
.text-bold {
  font-weight: 600;
}
.display-mobile {
  display: none;
  @media (max-width: 640px) {
    display: block;
  }
}
.hide-mobile {
  @media (max-width: 640px) {
    display: none;
  }
}

.loading-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
}

.loader {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  z-index: 100000000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
/////////////////// Onboard ////////////////////
.onboard-body {
  display: flex;
  flex-direction: column;
  background-color: #2c415a;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 40px 60px;
  min-height: calc(100% - 80px); // height of NAV
  font-family: Raleway, sans-serif;

  @media (max-width: 640px) {
    padding: 30px 30px 30px;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100% - 134px); // height of NAV
  }
}

.p-card-content {
  padding: 0px;
}
.p-card {
  border-radius: 20px;
}
.p-inputtext {
  font-family: Raleway, sans-serif;
}
.p-inputtext.p-inputtext-sm {
  font-size: 1rem;
}
.p-card-blue {
  background-color: #4a8fe740;
  box-shadow: 0;
}
.p-datatable-table {
  font-family: Raleway, sans-serif;
  color: #374151;
  font-weight: 300;
}
.p-datatable .p-datatable-tbody .p-rowgroup-header {
  background-color: #f9fafb;
  border-top: 3px solid #e5e7eb;
}

.p-column-title {
  font-weight: 400;

  @media (max-width: 690px) {
    font-size: 11px;
    line-height: 13px;
  }
}
.p-column-header-first {
  max-width: 25vw;
  font-style: italic;
  color: #374151;
  font-weight: 300;
}
.packages-table .p-datatable-wrapper {
  border-radius: 10px;
  max-width: 100vw;
  min-width: 72vw;
  background-color: #ffffff;
  @media (min-width: 690px) {
    padding: 28px;
  }
}

.packages-table {
  @media (max-width: 340px) {
    margin: 0px -30px;
    table {
      table-layout: fixed;
      word-break: break-all;
    }
  }
}
.p-column-title {
  width: 100%;
}
.p-column-header-name {
  font-size: 30px;
  font-family: Mukta, sans-serif;
  font-weight: 600;
  text-align: left;
  @media (max-width: 690px) {
    text-align: center;
    font-size: 17px;
  }
}
.p-column-header-price {
  font-size: 22px;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-align: left;
  color: #3b82f6;
  width: fit-content;
  @media (max-width: 690px) {
    font-size: 17px;
  }
}

.p-column-header-mid {
  @media (max-width: 690px) {
    min-width: 21vw;
  }
}

.p-column-btn-text {
  font-size: 0.875rem; //14px
  @media (max-width: 690px) {
    font-size: 0.6875rem; //11px
  }
}
.p-button.p-button-sm {
  padding: 0.5rem 0.75rem;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  vertical-align: baseline;
  padding: 0rem 1rem 0rem 1rem;
  @media (max-width: 690px) {
    padding: 0.375rem;
  }
}
.p-datatable .p-datatable-tbody > tr > td {
  font-size: 17px;
  color: #4b5563;
  @media (max-width: 690px) {
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 0.5rem;
  }
}
.p-column-header-buttons {
  margin: 0;
  @media (max-width: 690px) {
    margin: 0 auto;
  }
}
/////////////////// Google Places  ////////////////////

//  css partly working
.pac-target-input-loc {
  --gmpx-color-primary: #4b5563;
  --gmpx-font-family-base: Raleway, sans-serif;
  --gmpx-font-size-base: 1rem;
  --gmpx-color-surface: #fff;
  color: #4b5563;
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  margin: 0;
  background: #ffffff;
  appearance: none;
  border-radius: 6px;
}

/////////////////// Dashboard  ////////////////////
.p-card-gray {
  background-color: #f9fafb;
  box-shadow: none;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
}

.dashboard-table-date {
  font-weight: 400;
  font-size: 0.6875rem;
  font-style: italic;
}

.dashboard-table {
  font-family: Raleway, sans-serif;
  font-size: 0.875rem;
  thead {
    display: none;
  }
}

.dashboard-nav {
  font-family: Raleway, sans-serif;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    li {
      float: left;
      padding: 0rem 1rem;
      border-right: 1px solid #34313b;
      font-size: 1.25rem;
      color: #34313b;
      cursor: pointer;

      &:last-child {
        border-right: none;
      }
    }
  }
}

.nav-button {
  color: white;
  background-color: rgb(70, 70, 70);
  border: none;
}

.cardMenuItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.75rem;
  margin: 0;
}

.cardMenuItem-selected {
  background-color: #ebf3ff;
  border-left: solid 2px #3b82f6;
}

.cardMenuItem:hover {
  cursor: pointer;
}

.nav-secondary-container {
  border-top: 1px solid rgb(220, 220, 220);
}

.settings-split-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  min-width: fit-content;
  width: 100%;
  max-width: 100%;
  height: fit-content;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.settings-split-button > .p-button {
  display: flex;
  justify-content: center;
  min-width: fit-content;
  width: 15rem;
  max-width: 100%;
  border: none;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
}

.settings-split-button > .p-button:is(:nth-child(2)) {
  background-color: #f9fafb;
}

.settings-split-button > .p-button > .split-button-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.settings-split-button > .p-button > .split-button-content > .p-button-label {
  width: fit-content;
}

#settings-tiermenu {
  padding: 0;
  border: none;
}

.address-dropdown {
  width: 25rem;
  max-width: 100%;
  padding-left: 2rem;
}

#settings-table {
  flex-grow: 1;
  min-width: fit-content;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border-radius: 5px;
}

#settings-table
  > .p-card-body
  > .p-card-content
  > .p-dataview
  > .p-dataview-header {
  padding: 0;
  border: none;
  background-color: transparent;
}

#settings-table
  > .p-card-body
  > .p-card-content
  > .p-dataview
  > .p-dataview-header
  > div
  > div:last-child {
  padding: 0;
  border-top: 1px solid #e5e7eb;
  padding: 0.75rem;
  background-color: #f9fafb;
}

#settings-button {
  min-width: 2.5rem;
  min-height: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
}

#search-services-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  border: 2px solid #d1d5db;
  border-radius: 6px;
  padding: 0 0.5rem;

  #search-services {
    width: 100%;
    border: none;
  }

  #search-services:focus {
    border: none;
    outline: none;
    outline-color: transparent;
    outline-width: 0;
  }

  #search-services:enabled:focus {
    border: none;
    outline: none;
    outline-color: transparent;
    outline-width: 0;
  }

  .p-inputtext:enabled:focus {
    border: none;
    outline: none;
    outline-color: transparent;
    outline-width: 0;
    box-shadow: none;
  }
}

.nested-table {
  background-color: rgb(249, 250, 251);
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
}

.nested-table
  > .p-datatable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-thead
  > tr
  > th {
  vertical-align: middle;
}

.p-button .p-button-label {
  transition: none;
  transition-duration: 0s;
}

.full-width-xs {
  @media (max-width: 320px) {
    width: 100%;
  }
}

.p-tooltip-text {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.p-tooltip-text > .products-tooltip > .p-card-body .p-card-content > div > ul {
  display: flex;
  margin: 0;
  padding: 0;

  min-height: fit-content;
  height: fit-content;
  max-height: fit-content;
}

.location-scale {
  height: 62.51px;
  width: 133%;
  transform: scale(0.75);
  transform-origin: top left;
  border-radius: 8px;
  margin-bottom: -13px;
}

#package-toggle > .p-selectbutton.p-button-group.p-component {
  max-width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(220, 220, 220);
  border-radius: 25px;
}

#package-toggle
  > .p-selectbutton.p-button-group.p-component
  > .p-button.p-component {
  border-radius: 25px;
}

#package-toggle
  > .p-selectbutton.p-button-group.p-component
  > .p-button.p-component:is(.p-highlight) {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

#package-toggle
  > .p-selectbutton.p-button-group.p-component
  > .p-button.p-component:not(.p-highlight) {
  background-color: transparent;
  border: none;
  color: gray;
}

#package-toggle
  > .p-selectbutton.p-button-group.p-component
  > .p-button.p-component
  > .p-button-label {
  font-size: 12px;
}

.package-card-gray {
  font-family: Raleway, sans-serif;

  background-color: #f6f6f6;
  box-shadow: none;
  border-radius: 10px;
  .p-card-body {
    padding: 1.125rem;
  }
}

.package-card-subtext {
  font-family: sans-serif;
  font-size: 12px;
  color: gray;
}

.plain-card {
  background: #fff;
  display: flex;
  flex-direction: column;
  font-family: Raleway, sans-serif;

  height: calc(
    100vh - 195px
  ); // minus nav, and spacing // calculate desktop view?
  overflow: hidden;
  border-radius: 20px;
  padding: 1.25rem;
  max-width: 1372px;
  .scroll-content {
    flex: 1;
    overflow: auto;
  }
}
.p-dialog {
  box-shadow: none;
}
.max-content-sm {
  @media (max-width: 690px) and (min-width: 300px) {
    width: max-content;
  }
}
.password-guide {
  font-family: Raleway, sans-serif;
  line-height: 120%;
  color: #4b5563;
  font-size: 11px;
  ul {
    padding-inline-start: 1rem;
    li {
      list-style-type: disc;
    }
  }
}
.products-tooltip {
  font-size: 0.75rem;
  letter-spacing: 0em;
  line-height: 1.4em;
  font-family: Raleway, sans-serif;
  color: #34313b;

  // styled directly due to coming in through markdown
  h6 {
    font-size: 0.875rem;
    letter-spacing: 0em;
    line-height: 1.4em;
    font-weight: 700;
    font-family: Mukta, sans-serif;
    color: #34313b;
  }
  li {
    list-style: inside;
  }
}
// Package container increment input
.package-inputnumber {
  height: 2.5rem;
  .p-button-plain {
    border: 1px solid #6b7280;
    background: transparent;
    color: #6b7280;
  }
  .p-inputnumber-button-up,
  .p-inputnumber-button-down {
    width: 2.625rem;
  }
  .p-inputnumber-input {
    width: 4.5rem;
    text-align: center;
  }
}
.light-blue-bg {
  background-color: #e3ebf5;
  height: 2.5rem;
  max-height: 2.5rem;
  width: 127px;
}
.p-button-text {
  color: #6b7280;
  font-size: 0.875rem;
  font-weight: 700;
}
